import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';

const center = {
    lat: 37.599998,
    lng: -0.983333
};
const containerStyle = {
    width: '100%',
    height: '400px',
    borderRadius: '5px',
    marginTop: '20px'
};
export interface Position {
    lat: number | null;
    lng: number | null;
}
export interface MapMarkerProps {
    position: Position | null;
}
const MapMarker = (marker: MapMarkerProps) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCeWFEt22CP87zpri8-qJPzKxx2v5Qcibo',
        libraries: ['places']
    });
    const [map, setMap] = useState(null);

    const onUnmount = useCallback(function callback(map: any) {
        setMap(null);
    }, []);
    const [markers, setMarkers] = useState<Position[]>([]);

    useEffect(() => {
        if (marker.position) setMarkers([marker.position]);
    }, [marker.position?.lat, marker.position?.lng]);

    return isLoaded && marker.position && marker.position.lat && marker.position.lng ? (
        <>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={{ lat: marker.position.lat, lng: marker.position.lng }}
                zoom={9}
                onUnmount={onUnmount}
            >
                {markers.length > 0 &&
                    markers.map((e: Position, key) => {
                        return (
                            e.lat &&
                            e.lng && (
                                <Marker
                                    position={{ lat: e.lat, lng: e.lng }}
                                    key={e.lat + e.lng + key}
                                />
                            )
                        );
                    })}
            </GoogleMap>
        </>
    ) : (
        <></>
    );
};

export default MapMarker;
