@mixin item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid white;
    font-weight: 600;
    font-size: 14px;
    transition: 0.4s;
}
.container {
    width: auto;
    display: flex;
    justify-content: center;
}
.containerItems {
    width: fit-content;
    gap: 10px;
    display: flex;
    flex-direction: row;
    .item {
        @include item();
        background-color: var(--color-white);
        color: var(--color-info);
        &__active {
            @include item();
            background-color: var(--color-info);
            color: var(--color-white);
        }
    }
    .item:hover {
        background-color: var(--color-white);
        color: var(--color-info);
        font-weight: 600;
        transition: 0.4s;
        cursor: pointer;
    }
}
.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
}
