import { useField } from 'formik';
import { useState } from 'react';

import styles from './MultiInput.module.scss';

interface MultiInputProps {
    name: string;
    type?: 'text' | 'number' | 'date' | 'time';
    placeholder: string;
}

const MultiInput = ({ name, placeholder, ...props }: MultiInputProps) => {
    const [field, meta, helpers] = useField(name);

    const [currentValue, setCurrentValue] = useState<string | number>('');

    const { value } = meta;
    const { setValue } = helpers;

    const removeItem = (index: number) => {
        let arrAux = [...value];
        if (index > -1) {
            arrAux.splice(index, 1);
            setValue(arrAux);
        }
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.containerItems} {...field} {...props}>
                    <div className={styles.inputContainer}>
                        <input
                            className={styles.input}
                            lang={'es-ES'}
                            value={currentValue}
                            onChange={(v) => {
                                setCurrentValue(v.target.value);
                            }}
                            placeholder={placeholder}
                            id={styles.input}
                        />
                        <button
                            className={styles.buttonAdd}
                            onClick={() => {
                                setValue([...value, currentValue]);
                                setCurrentValue('');
                            }}
                            type={'button'}
                            disabled={
                                currentValue.toString().length < 1 || (value && value.length > 2)
                            }
                        >
                            Añadir teléfono
                        </button>
                    </div>
                    {value &&
                        value.length > 0 &&
                        value.map((val: any, i: number) => {
                            return (
                                <div key={i + styles.item} className={styles.item}>
                                    <label className={styles.item__label}>{val}</label>
                                    <div
                                        className={styles.item__remove}
                                        onClick={() => {
                                            removeItem(i);
                                        }}
                                    >
                                        x
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </>
    );
};

export default MultiInput;
