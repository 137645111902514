import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import Header from '@/components/Header/Header';
import MultiInput from '@/components/InputsFormik/MultiInput/MultiInput';

import { Form, Formik, FormikProps, useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import styles from './PersonalInformation.module.scss';
import { PersonalInformationSchema } from './PersonalInformation.yup';
import '@reach/combobox/styles.css';
import InputRow from '@/components/CustomInputs/InputRow/InputRow';
import { useAppDispatch } from '@/index';
import { personalInformationActions } from '@/store/personalInformation';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/store/rootReducer';
import { useSelector } from 'react-redux';
import { CitizenData } from '@/store/personalInformation/postPersonalInformation.slice';

export interface PersonalInformationForm {
    userName: string;
    userLastName: string;
    dnicif: string;
    tel: string[];
    email: string;
}
export const headerNavigateOptions: any = {
    1: '/personal-information',
    2: '/property-information',
    3: '/resumen-information'
};
const PersonalInformation = () => {
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    const [initialStateForm, setInitialStateForm] = useState<PersonalInformationForm>({
        userName: '',
        userLastName: '',
        dnicif: '',
        tel: [],
        email: ''
    });

    const citizenData: CitizenData = useSelector(
        (state: RootState) => state.personalInformation.personalInformation.data.citizen
    );

    const formRef = useRef<any>();

    const handleSubmitForm = (data: PersonalInformationForm) => {
        appDispatch(
            personalInformationActions.setCitizenInformation({
                nif: data.dnicif ?? '',
                name: data.userName ?? '',
                last_name: data.userLastName ?? '',
                email: data.email ?? '',
                phone_numbers: data.tel ?? []
            })
        );
        navigate('/property-information');
    };
    useEffect(() => {
        if (citizenData)
            setInitialStateForm({
                userName: citizenData.name,
                userLastName: citizenData.last_name,
                dnicif: citizenData.nif,
                tel: citizenData.phone_numbers,
                email: citizenData.email
            });
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Formik
                    enableReinitialize
                    innerRef={formRef}
                    initialValues={initialStateForm}
                    validationSchema={PersonalInformationSchema}
                    onSubmit={handleSubmitForm}
                    validateOnBlur={true}
                    validateOnMount={true}
                >
                    {(formik: FormikProps<PersonalInformationForm>) => (
                        <>
                            <Form className={styles.formContainer}>
                                <Header
                                    title={`${1}. Información Personal`}
                                    position={1}
                                    handleSelectOption={(pos: number) => {
                                        if (
                                            formik.errors &&
                                            Object.keys(formik.errors).length < 1
                                        ) {
                                            handleSubmitForm(formik.values);
                                            navigate(headerNavigateOptions[pos]);
                                        }
                                    }}
                                    disabledOptions={[false, false, false]}
                                    typeButton={'submit'}
                                />
                                <p>
                                    Si ya posees una propiedad registrada en nuestro sistema
                                    asegúrate de que usas el mismo DNI/CIF y correo electrónico que
                                    usaste la última vez
                                </p>
                                <InputRow
                                    label="Nombre (*)"
                                    name="userName"
                                    placeholder="Nombre"
                                    type="text"
                                />
                                <InputRow
                                    label="Apellidos (*)"
                                    name="userLastName"
                                    placeholder="Apellidos"
                                    type="text"
                                />
                                <InputRow
                                    label="DNI/CIF (*)"
                                    name="dnicif"
                                    placeholder="DNI/CIF"
                                    type="text"
                                />
                                <div className={styles.inputRow}>
                                    <div className={styles.label}>
                                        <div className={styles.label__icon} /> Números de telefono
                                    </div>
                                    <div className={styles.inputs}>
                                        <MultiInput name="tel" placeholder={'Teléfono'} />
                                    </div>
                                </div>
                                <InputRow
                                    label="Dirección de correo (*)"
                                    name="email"
                                    placeholder="Correo"
                                    type="email"
                                />
                                <div className={styles.button}>
                                    <Button
                                        variant={ButtonVariants.INFO}
                                        size={ButtonSizes.LARGE}
                                        type="submit"
                                    >
                                        CONTINUAR
                                    </Button>
                                </div>
                                {formik.errors && Object.keys(formik.errors).length > 0 && (
                                    <label className={styles.warning}>
                                        Algunos campos no se completaron correctamente
                                    </label>
                                )}
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default PersonalInformation;
