import classNames from 'classnames';
import styles from './Header.module.scss';
export interface HeaderProps {
    title: string;
    position: number;
    handleSelectOption: (pos: number) => void;
    disabledOptions: boolean[];
    typeButton: 'submit' | 'button';
}
const Header = ({
    title,
    position,
    handleSelectOption,
    disabledOptions,
    typeButton
}: HeaderProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.bannerIMG} />
            <div className={styles.header}>
                <div className={styles.title}>{title}</div>
                <div className={styles.positions}>
                    <button
                        className={classNames(
                            styles.option,
                            position === 1 && styles.option__active
                        )}
                        onClick={() => handleSelectOption(1)}
                        type={typeButton}
                        disabled={disabledOptions[0]}
                    >
                        1
                    </button>
                    <button
                        className={classNames(
                            styles.option,
                            position === 2 && styles.option__active
                        )}
                        onClick={() => handleSelectOption(2)}
                        type={typeButton}
                        disabled={disabledOptions[1]}
                    >
                        2
                    </button>
                    <button
                        className={classNames(
                            styles.option,
                            position === 3 && styles.option__active
                        )}
                        onClick={() => handleSelectOption(3)}
                        type={typeButton}
                        disabled={disabledOptions[2]}
                    >
                        3
                    </button>
                </div>
                <div className={styles.line} />
            </div>
        </div>
    );
};

export default Header;
