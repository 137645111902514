import DynamicToggle from '@/components/InputsFormik/DynamicToggle/DynamicToggle';
import { DataToggle } from '@/models/InputsForm';

import styles from './InputToggleRow.module.scss';

const InputToggleRow = ({
    name,
    label,
    data
}: {
    name: string;
    label: string;
    data: DataToggle[];
}) => {
    return (
        <div className={styles.inputRow}>
            <div className={styles.label}>
                <div className={styles.label__icon} /> {label}
            </div>
            <div className={styles.inputs}>
                <DynamicToggle name={name} label="" data={data} />
            </div>
        </div>
    );
};

export default InputToggleRow;
