import * as Yup from 'yup';

export const PersonalInformationSchema = () => {
    const typeInputSchema = {
        userName: Yup.string().required().trim(),
        userLastName: Yup.string().required().trim(),
        dnicif: Yup.string().required().trim(),
        tel: Yup.array().of(Yup.string()),
        email: Yup.string().email().required().trim()
    };
    let schema = Yup.object(typeInputSchema);
    return schema;
};
