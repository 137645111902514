import { Navigate } from 'react-router-dom';
import PrivateRouteController from './PrivateRoute.controller';
import { PrivateRouteProps } from './PrivateRoute.types';

function PrivateRoute(props: PrivateRouteProps) {
    const { isAuthenticated, location } = PrivateRouteController();

    return isAuthenticated ? (
        props.children
    ) : (
        <Navigate to="/login" replace state={{ path: location.pathname }} />
    );
}

export default PrivateRoute;
