import accessToken from '@/utils/helpers/accessToken.helper';
import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
export interface SummaryData {
    citizen: {
        nif: string;
        name: string;
        last_name: string;
        email: string;
        phone_numbers: string[];
    };
    property: {
        latitude: number | null;
        longitude: number | null;
        is_company: boolean;
        has_elders: boolean;
        has_minors: boolean;
        people_on_property: number;
    };
}

export const getSummaryThunk = createAsyncThunk<
    SummaryData,
    { propertyCode: string },
    { rejectValue: Error }
>('summaryData', async (params, thunkApi) => {
    return request({
        url: `/api/citizens/properties/${params.propertyCode}/info`,
        method: 'GET',
        extraHeaders: {
            Authorization: `Bearer ${accessToken.get()}`
        }
    })
        .then((res: ApiResponse<SummaryData>) => {
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            toast.error('Falló al enviar el formulario');
            return thunkApi.rejectWithValue(err.data);
        });
});

export const getSummarySlice = createSlice({
    name: 'getSummary',
    initialState: {
        citizen: {
            nif: '',
            name: '',
            last_name: '',
            email: '',
            phone_numbers: []
        },
        property: {
            latitude: null,
            longitude: null,
            is_company: false,
            has_elders: false,
            has_minors: false,
            people_on_property: 0
        }
    } as SummaryData,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSummaryThunk.fulfilled, (state, { payload }) => {
            (state.citizen = payload.citizen), (state.property = payload.property);
        });
    }
});
