import { useField } from 'formik';
import styles from './Input.module.scss';

interface InputProps {
    label: string;
    name: string;
    type?: 'text' | 'number' | 'date' | 'time' | 'email';
    placeholder?: string;
    [x: string]: any;
}
const Input = ({ label, name, ...props }: InputProps) => {
    const [field, meta] = useField({ ...props, name });
    return (
        <>
            <input className={styles.input} lang={'es-ES'} {...field} {...props} min={0} />
        </>
    );
};

export default Input;
