.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
}
.bannerIMG {
    background-image: url('./../../assets/images/bg.jpg');
    background-size: cover;
    width: 100%;
    height: 100px;
    background-position: 0px;
}
.header {
    display: grid;
    grid-template-areas: 'title title positions' 'line line line';
    width: 100%;
    min-height: 50px;
    height: fit-content;
    margin-top: 15px;

    .title {
        grid-area: title;
        font-weight: bolder;
        font-size: 24px;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    .positions {
        grid-area: positions;
        display: flex;
        flex-direction: row;
        justify-content: end;
        .option {
            width: 35px;
            height: 35px;
            background-color: var(--color-info);
            color: white;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            margin-left: 10px;
            opacity: 0.5;
            border: none;
            cursor: pointer;
            &__active {
                opacity: 1;
            }
        }
        .option:disabled {
            background-color: gray;
            cursor: not-allowed;
        }
    }
    .line {
        grid-area: line;
        border-bottom: 7px solid var(--color-info);
    }
}
