@import '@/styles/tools/responsive';
.container {
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-image: url('./../../assets/images/bg.jpg');
    background-size: cover;
    flex-direction: column;
    background-position: center;
    .titleContainer {
        width: 100%;
        min-height: 150px;
        opacity: 0.7;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
            text-align: center;
            width: 80%;
            color: white;
            font-size: 28;
            font-weight: bold;
        }
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.339);
        height: 200px;
        width: 320px;
        backdrop-filter: blur(4px);
        border-radius: 10px;
        padding: 10px 40px;
    }
    .buttons {
        width: 100%;
        height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 30px 50px;
        .button {
            height: 50px;
        }
    }
}

@include breakpoint(mobile) {
    .container {
        .titleContainer {
            .title {
                font-size: 24;
            }
        }
        .content {
            width: 340px;
            padding: 0;
        }
    }
}
