import { FunctionComponent, SVGAttributes } from 'react';
export enum ButtonVariants {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERCIARY = 'terciary',
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    PRIMARY_OUTLINE = 'primaryOutLine',
    SECONDARY_OUTLINE = 'secondaryOutLine',
    TERCIARY_OUTLINE = 'terciaryOutLine',
    SUCCESS_OUTLINE = 'successOutLine',
    INFO_OUTLINE = 'infoOutLine',
    ERROR_OUTLINE = 'errorOutLine',
    WARNING_OUTLINE = 'warningOutLine'
}
export enum ButtonSizes {
    XSMALL = 'xsmall',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    XLARGE = 'xlarge',
    ADAPTABLE = 'adaptable'
}
export interface ButtonProps {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button' | undefined;
    form?: string;
    IconLeft?: FunctionComponent<SVGAttributes<SVGElement>> | any;
    IconRight?: FunctionComponent<SVGAttributes<SVGElement>> | any;
    variant: ButtonVariants;
    size: ButtonSizes;
}
