import { createSlice } from '@reduxjs/toolkit';
import { rootThunk } from './../rootThunk';
export const toggleLoaderSlice = createSlice({
    name: 'loader',
    initialState: { isLoading: false },
    reducers: {
        toggleLoading: (state) => {
            state.isLoading = !state.isLoading;
        }
    },
    extraReducers: rootThunk
});

export const { toggleLoading } = toggleLoaderSlice.actions;
