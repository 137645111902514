import { combineReducers } from 'redux';
import { getSummarySlice } from './summary.slice';

export const summaryReducer = combineReducers({
    getSummary: getSummarySlice.reducer
});

export const summaryActions = {
    ...getSummarySlice.actions
};
