.container {
    display: flex;
    flex-direction: column;
}
.searchContainer {
    margin-bottom: 10px;
}
.comboBoxInput {
    width: calc(100% - 16px);
    border: 1px solid #777;
    border-radius: 5px;
    padding: 7px;
}

.comboBoxInput:focus {
    outline: none;
}

.address {
    text-align: start;
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    &__text {
        margin-right: 7px;
    }
    &__coords {
        color: var(--color-info);
    }
}
