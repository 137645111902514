import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { loaderReducer } from './loader';
import { personalInformationReducer } from './personalInformation';
import { summaryReducer } from './summary';
export type RootState = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
    loader: loaderReducer,
    auth: authReducer,
    personalInformation: personalInformationReducer,
    summary: summaryReducer
});

export default rootReducer;
