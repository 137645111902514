import { BeatLoader } from 'react-spinners';

interface SpinnerProps {
    isLoading: boolean;
}
const Spinner = ({ isLoading }: SpinnerProps) => {
    return (
        <div>
            <BeatLoader size={35} color="#516DA4" loading={isLoading} />
        </div>
    );
};

export default Spinner;
