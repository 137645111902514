import Input from '@/components/InputsFormik/Input/Input';
import styles from './InputRow.module.scss';
const InputRow = ({
    name,
    label,
    placeholder,
    type
}: {
    name: string;
    label: string;
    placeholder: string;
    type: 'number' | 'time' | 'text' | 'date' | 'email';
}) => {
    return (
        <div className={styles.inputRow}>
            <div className={styles.label}>
                <div className={styles.label__icon} /> {label}
            </div>
            <div className={styles.inputs}>
                <Input name={name} label="" placeholder={placeholder} type={type} />
            </div>
        </div>
    );
};

export default InputRow;
