@import '@/styles/tools/responsive';
.inputRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;

    .label {
        font-size: large;
        font-weight: bolder;
        min-width: 300px;
        width: 50%;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        &__icon {
            width: 15px;
            height: 15px;
            background-color: var(--color-info);
            border-radius: 20px;
            margin-right: 10px;
            align-self: flex-start;
            //align-self: center;
        }
    }
    .inputs {
        display: grid;
        grid-template-columns: repeat(auto-fill, 100%);
        gap: 20px;
        width: 100%;
    }
}

@include breakpoint(lessTablet) {
    .inputRow {
        flex-direction: column;
    }
}
