import Loader from '@/components/Loader/Loader';
import { RootState } from '@/store/rootReducer';
import React from 'react';
import { useSelector } from 'react-redux';
import NavBar from '../components/NavBar/NavBar';
import styles from './Layout.module.scss';
interface LayoutProps {
    children?: React.ReactNode;
}
const Layout = ({ children }: LayoutProps) => {
    const isLoading = useSelector((state: RootState) => state.loader.setLoader.isLoading);
    return (
        <>
            <Loader isLoading={isLoading} />
            <div className={styles.container}>
                {/* <NavBar /> */}
                <div className={styles.content}>{children}</div>
            </div>
        </>
    );
};

export default Layout;
