import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useCallback, useState } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import Geocode from 'react-geocode';
import styles from './CustomMap.module.scss';
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption
} from '@reach/combobox';
const containerStyle = {
    width: '100%',
    height: '400px',
    borderRadius: '5px'
};

const center = {
    lat: 37.599998,
    lng: -0.983333
};

export interface CustomMapProps {
    selected: { lat: number; lng: number; address: any } | null;
    setSelected: ({ lat, lng, address }: { lat: number; lng: number; address: any }) => void;
}
const CustomMap = ({ selected, setSelected }: CustomMapProps) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCeWFEt22CP87zpri8-qJPzKxx2v5Qcibo',
        libraries: ['places']
    });

    const [map, setMap] = useState(null);

    const onUnmount = useCallback(function callback(map: any) {
        setMap(null);
    }, []);

    const handleClickMap = (e: any) => {
        Geocode.setApiKey('AIzaSyCeWFEt22CP87zpri8-qJPzKxx2v5Qcibo');
        Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then((res) => {
            const address = res.results[0].formatted_address;
            setSelected({ lat: e.latLng.lat(), lng: e.latLng.lng(), address });
        });
    };

    return isLoaded ? (
        <>
            <div className={styles.container}>
                <div className={styles.searchContainer}>
                    <PlacesAutoComplete
                        setSelected={setSelected}
                        addressValue={selected?.address ?? ''}
                    />
                </div>

                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={
                        selected && selected.lat !== 0 && selected?.lng != 0
                            ? { lat: selected.lat, lng: selected.lng }
                            : center
                    }
                    zoom={9}
                    onUnmount={onUnmount}
                    onClick={handleClickMap}
                >
                    {/* Child components, such as markers, info windows, etc. */}

                    {selected && selected.lat && selected.lng && (
                        <Marker position={{ lat: selected.lat, lng: selected.lng }} />
                    )}
                </GoogleMap>
                <label className={styles.address}>
                    <div className={styles.address__text}>Las coordenadas seleccionadas son: </div>

                    <div className={styles.address__coords}>
                        {selected && selected.lat !== 0 && selected.lng !== 0 && (
                            <>{`${selected.lat} ,${selected.lng}`}</>
                        )}
                    </div>
                </label>
            </div>
        </>
    ) : (
        <>...Cargando</>
    );
};
const PlacesAutoComplete = ({
    setSelected,
    addressValue
}: {
    addressValue: string;
    setSelected: (v: any) => void;
}) => {
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions
    } = usePlacesAutocomplete();

    const handleSelect = async (address: any) => {
        setValue(address, false);
        clearSuggestions();

        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);

        setSelected({ lat, lng, address });
    };

    return (
        <Combobox onSelect={handleSelect}>
            <ComboboxInput
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={!ready}
                className={styles.comboBoxInput}
                placeholder={addressValue.length <= 1 ? 'Dirección' : addressValue}
            />

            <ComboboxPopover>
                <ComboboxList>
                    {status === 'OK' &&
                        data.map(({ place_id, description }) => (
                            <ComboboxOption key={place_id} value={description} />
                        ))}
                </ComboboxList>
            </ComboboxPopover>
        </Combobox>
    );
};

export default CustomMap;
