const get = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        return accessToken;
    }
    return null;
}

const set = (accessToken: string) => {
    localStorage.setItem('accessToken', accessToken);
}

const remove = () => {
    localStorage.removeItem('accessToken');
}

const exists = () => {
    return !!get();
}

const accessToken = {
    get,
    set,
    remove,
    exists
}

export default accessToken;