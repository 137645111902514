import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import Input from '@/components/Inputs/Input/Input';
import InputPassword from '@/components/Inputs/InputPassword/InputPassword';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.scss';

import UserIcon from '@/assets/icons/person-circle.svg';
import LockIcon from '@/assets/icons/lock-icon.svg';
import { useState } from 'react';
import { useAppDispatch } from '@/index';
import { loginThunk } from '@/store/auth/login.slice';

const Login = () => {
    const navigate = useNavigate();

    const [dniCif, setDniCif] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const appDispatch = useAppDispatch();

    const login = async () => {
        appDispatch(loginThunk({ nif: dniCif, property_code: password })).then((res) => {
            navigate(`/summary/${password}`);
        });
    };
    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            login();
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    SISTEMA DE LOCALIZACIÓN RURAL DEL MUNICIPIO DE JUMILLA
                </div>
            </div>
            <div className={styles.content}>
                <Input
                    placeholder="Introduce tu DNI"
                    IconLeft={UserIcon}
                    value={dniCif}
                    onChange={setDniCif}
                    onKeyPress={handleKeyPress}
                />
                <InputPassword
                    placeholder="Código"
                    IconLeft={LockIcon}
                    value={password}
                    onChange={setPassword}
                    onKeyPress={handleKeyPress}
                />
                <div className={styles.buttons}>
                    <Button
                        size={ButtonSizes.LARGE}
                        variant={ButtonVariants.INFO}
                        onClick={login}
                        disabled={dniCif.length < 1 || password.length < 1 ? true : false}
                    >
                        ENTRAR
                    </Button>
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default Login;
