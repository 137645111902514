import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import Header from '@/components/Header/Header';
import MapMarker from '@/components/MapMarker/MapMarker';
import { useAppDispatch } from '@/index';
import { postPersonalInformationThunk } from '@/store/personalInformation/postPersonalInformation.slice';
import { RootState } from '@/store/rootReducer';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { headerNavigateOptions } from '../PersonalInformation/PersonalInformation';
import styles from './ResumenInformation.module.scss';

const ResumenInformation = () => {
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();

    const citizen = useSelector(
        (state: RootState) => state.personalInformation.personalInformation.data.citizen
    );

    const property = useSelector(
        (state: RootState) => state.personalInformation.personalInformation.data.property
    );
    const isValidate =
        citizen.name.length > 0 &&
        citizen.last_name.length > 0 &&
        citizen.nif.length > 0 &&
        citizen.email.length > 0 &&
        property.address.length > 0;

    const ItemData = ({
        title,
        data
    }: {
        title: string;
        data: { label: string; text: string }[];
    }) => {
        return (
            <div className={styles.item}>
                <div className={styles.title}>{title}</div>
                <div className={styles.itemData}>
                    {data.map((element, i) => {
                        return (
                            <div className={styles.itemDataRow} key={i + styles.itemDataRow}>
                                <div className={styles.itemDataRow__label}>{element.label}</div>
                                <div className={styles.itemDataRow__text}>{element.text}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Header
                    title={`${3}. Resumen`}
                    position={3}
                    handleSelectOption={(pos: number) => {
                        navigate(headerNavigateOptions[pos]);
                    }}
                    disabledOptions={[false, false, false]}
                    typeButton={'button'}
                />
                <div className={styles.contentRow}>
                    <ItemData
                        title="Información Personal"
                        data={[
                            { label: 'Nombre:', text: citizen.name },
                            { label: 'Apellidos:', text: citizen.last_name },
                            { label: 'DNI:', text: citizen.nif },
                            { label: 'Teléfono:', text: citizen.phone_numbers.toString() },
                            { label: 'Correo Electrónico:', text: citizen.email }
                        ]}
                    />
                    <ItemData
                        title="Información de la propiedad"
                        data={[
                            { label: '¿Es una empresa?', text: property.is_company ? 'Si' : 'No' },
                            {
                                label: '¿Hay personas mayores de 65 años?',
                                text: property.has_elders ? 'Si' : 'No'
                            },
                            {
                                label: '¿Hay personas menores de 18 años?',
                                text: property.has_minors ? 'Si' : 'No'
                            },
                            {
                                label: 'Número de personas',
                                text: property.people_on_property.toString()
                            }
                        ]}
                    />
                </div>
                <ItemData
                    title="Ubicación de la propiedad"
                    data={[
                        {
                            label: 'Coordenadas de la propiedad:',
                            text: `${property.latitude},${property.longitude}`
                        }
                    ]}
                />

                <MapMarker position={{ lat: property.latitude, lng: property.longitude }} />
                <div className={styles.button}>
                    {!isValidate && (
                        <div className={styles.warning}>Tiene campos requeridos sin completar</div>
                    )}
                    <Button
                        variant={ButtonVariants.INFO}
                        size={ButtonSizes.LARGE}
                        onClick={() => {
                            if (isValidate) {
                                appDispatch(postPersonalInformationThunk({ citizen, property }));
                                navigate('/');
                            }
                        }}
                    >
                        CONFIRMAR
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ResumenInformation;
