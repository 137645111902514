@import '@/styles/tools/responsive';

.container {
    background-color: var(--bg-main);

    .content {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }
}

@include breakpoint(lessTablet) {
}
@include breakpoint(smallTablet) {
    .container {
        .content {
            flex-direction: column;
        }
    }
}
@include breakpoint(mobile) {
}
