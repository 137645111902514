import { combineReducers } from 'redux';

import { authLoginSlice } from './login.slice';

export const authReducer = combineReducers({
    login: authLoginSlice.reducer
});

export const authActions = {
    ...authLoginSlice.actions
};
