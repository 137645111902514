@import '@/styles/tools/responsive';

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
}

.content {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.contentRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.item {
    margin-top: 40px;
    width: 100%;
    .title {
        font-size: 24px;
        font-weight: bolder;
        border-left: 5px solid var(--color-info);
        padding: 0 0 0 10px;
    }
    .itemData {
        display: flex;
        flex-direction: column;
        padding: 0 0 0 16px;
        .itemDataRow {
            font-size: 20;
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            &__label {
            }
            &__text {
                margin-left: 10px;
                color: #777;
            }
        }
    }
}
.button {
    width: 300px;
    align-self: center;
    margin: 30px 0;
}
@include breakpoint(lessTablet) {
    .contentRow {
        flex-direction: column;
    }
    .item {
        .title {
            width: 280px;
            overflow-wrap: break-word;
        }
        .itemData {
            .itemDataRow {
                flex-direction: column;
                &__label {
                    width: 280px;
                    overflow-wrap: break-word;
                }
                &__text {
                    width: 280px;
                    overflow-wrap: break-word;
                    margin-left: 0;
                    margin-top: 5px;
                }
            }
        }
    }
}
