import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import Header from '@/components/Header/Header';
import MapMarker from '@/components/MapMarker/MapMarker';
import { useAppDispatch } from '@/index';
import { RootState } from '@/store/rootReducer';
import { getSummaryThunk } from '@/store/summary/summary.slice';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Summary.module.scss';

const Summary = () => {
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    const params = useParams();
    const citizen = useSelector((state: RootState) => state.summary.getSummary.citizen);

    const property = useSelector((state: RootState) => state.summary.getSummary.property);

    useEffect(() => {
        appDispatch(getSummaryThunk({ propertyCode: params.propertyCode ?? '' }));
    }, [params]);
    const ItemData = ({
        title,
        data
    }: {
        title: string;
        data: { label: string; text: string }[];
    }) => {
        return (
            <div className={styles.item}>
                <div className={styles.title}>{title}</div>
                <div className={styles.itemData}>
                    {data.map((element) => {
                        return (
                            <div className={styles.itemDataRow}>
                                <div className={styles.itemDataRow__label}>{element.label}</div>
                                <div className={styles.itemDataRow__text}>{element.text}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Header
                    title="3. Resumen"
                    position={3}
                    handleSelectOption={() => {}}
                    disabledOptions={[true, true, false]}
                    typeButton={'button'}
                />
                <div className={styles.contentRow}>
                    <ItemData
                        title="Información Personal"
                        data={[
                            { label: 'Nombre:', text: citizen.name },
                            { label: 'DNI:', text: citizen.nif },
                            { label: 'Teléfono:', text: citizen.phone_numbers.toString() },
                            { label: 'Correo Electrónico:', text: citizen.email }
                        ]}
                    />
                    <ItemData
                        title="Información de la propiedad"
                        data={[
                            { label: '¿Es una empresa?', text: property.is_company ? 'Si' : 'No' },
                            {
                                label: '¿Hay personas mayores de 65 años?',
                                text: property.has_elders ? 'Si' : 'No'
                            },
                            {
                                label: '¿Hay personas menores de 18 años?',
                                text: property.has_minors ? 'Si' : 'No'
                            },
                            {
                                label: 'Número de personas',
                                text: property.people_on_property.toString()
                            }
                        ]}
                    />
                </div>
                <ItemData
                    title="Ubicación de la propiedad"
                    data={[
                        {
                            label: 'Coordenadas de la propiedad:',
                            text: `${property.latitude},${property.longitude}`
                        }
                    ]}
                />

                <MapMarker position={{ lat: property.latitude, lng: property.longitude }} />
                <div className={styles.button}>
                    <Button
                        variant={ButtonVariants.INFO}
                        size={ButtonSizes.LARGE}
                        onClick={() => navigate('/')}
                    >
                        CONFIRMAR
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Summary;
