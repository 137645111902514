import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import InputRow from '@/components/CustomInputs/InputRow/InputRow';
import InputToggleRow from '@/components/CustomInputs/InputToggleRow/InputToggleRow';
import Header from '@/components/Header/Header';
import InputMap from '@/components/InputsFormik/InputMap/InputMap';
import { useAppDispatch } from '@/index';
import { personalInformationActions } from '@/store/personalInformation';
import { PropertyData } from '@/store/personalInformation/postPersonalInformation.slice';
import { RootState } from '@/store/rootReducer';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { headerNavigateOptions } from '../PersonalInformation/PersonalInformation';
import styles from './PropertyInformation.module.scss';
import { PropertyInformationSchema } from './PropertyInformation.yup';

export interface PropertyInformationForm {
    address: { lat: number | null; lng: number | null; address: string };
    isCompany: number | boolean;
    over65: number | boolean;
    minor18: number | boolean;
    numberPeople: number;
}

const PropertyInformation = () => {
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    const propertyData: PropertyData = useSelector(
        (state: RootState) => state.personalInformation.personalInformation.data.property
    );

    const [initialStateForm, setInitialStateForm] = useState<PropertyInformationForm>({
        address: { lat: null, lng: null, address: '' },
        isCompany: 0,
        over65: 0,
        minor18: 0,
        numberPeople: 0
    });
    const handleSubmit = (data: PropertyInformationForm) => {
        appDispatch(
            personalInformationActions.setPropertyInformation({
                address: data.address!.address ?? '',
                latitude: data.address!.lat ?? null,
                longitude: data.address!.lng ?? null,
                is_company: data.isCompany === 1 ? true : false,
                has_elders: data.over65 === 1 ? true : false,
                has_minors: data.minor18 === 1 ? true : false,
                people_on_property: data.numberPeople ?? 0
            })
        );
        navigate('/resumen-information');
    };

    const dataToggle = [
        { text: 'Si', value: 1 },
        { text: 'No', value: 0 }
    ];

    useEffect(() => {
        if (propertyData)
            setInitialStateForm({
                address: {
                    address: propertyData.address,
                    lat: propertyData.latitude,
                    lng: propertyData.longitude
                },
                isCompany: propertyData.is_company ? 1 : 0,
                over65: propertyData.has_elders ? 1 : 0,
                minor18: propertyData.has_minors ? 1 : 0,
                numberPeople: propertyData.people_on_property
            });
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Formik
                    enableReinitialize
                    initialValues={initialStateForm}
                    validationSchema={PropertyInformationSchema}
                    onSubmit={handleSubmit}
                    validateOnBlur={true}
                    validateOnMount={true}
                >
                    {(formik: FormikProps<PropertyInformationForm>) => (
                        <>
                            <Header
                                title={`${2}. Información de la propiedad`}
                                position={2}
                                handleSelectOption={(pos: number) => {
                                    if (formik.errors && Object.keys(formik.errors).length < 1) {
                                        handleSubmit(formik.values);
                                        navigate(headerNavigateOptions[pos]);
                                    }
                                }}
                                disabledOptions={[false, false, false]}
                                typeButton={'submit'}
                            />
                            <Form className={styles.formContainer}>
                                <div className={styles.inputRow}>
                                    <div className={styles.label}>
                                        <div className={styles.label__icon} /> Dirección de la
                                        propiedad (*)
                                    </div>
                                    <div className={styles.inputs}>
                                        <InputMap name="address" />
                                    </div>
                                </div>

                                <InputToggleRow
                                    name="isCompany"
                                    label="¿La propiedad es una empresa?"
                                    data={dataToggle}
                                />
                                <InputToggleRow
                                    name="over65"
                                    label="¿Hay personas MAYORES de 65 años?"
                                    data={dataToggle}
                                />
                                <InputToggleRow
                                    name="minor18"
                                    label="¿Hay personas MENORES de 18 años?"
                                    data={dataToggle}
                                />
                                <InputRow
                                    name="numberPeople"
                                    label="¿Cuántas personas hay en la propiedad?"
                                    placeholder=""
                                    type="number"
                                />

                                <div className={styles.button}>
                                    <Button
                                        variant={ButtonVariants.INFO}
                                        size={ButtonSizes.LARGE}
                                        type={'submit'}
                                    >
                                        GUARDAR
                                    </Button>
                                </div>
                                {formik.errors && Object.keys(formik.errors).length > 0 && (
                                    <label className={styles.warning}>
                                        Algunos campos no se completaron correctamente
                                    </label>
                                )}
                            </Form>
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default PropertyInformation;
