import { FunctionComponent, SVGAttributes } from 'react';
import styles from './Input.module.scss';

interface InputProps {
    placeholder: string;
    onChange: (text: string) => void;
    IconLeft: FunctionComponent<SVGAttributes<SVGElement>>;
    value: string;
    secureTextEntry?: boolean;
    [x: string]: any;
}
const Input = ({
    placeholder,
    onChange,
    IconLeft,
    value,
    secureTextEntry = false,
    ...props
}: InputProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.containerIcon}>
                <IconLeft className={styles.icon} />
            </div>
            <input
                className={styles.input}
                onChange={(text) => onChange(text.currentTarget.value)}
                placeholder={placeholder}
                value={value}
                {...props}
                type={secureTextEntry ? 'password' : 'text'}
            />
        </div>
    );
};

export default Input;
