@import '@/styles/tools/responsive';

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    //overflow: scroll;
}

.content {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .inputRow {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 20px;

        .label {
            font-size: large;
            font-weight: bolder;
            min-width: 300px;
            width: 50%;
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            &__icon {
                width: 15px;
                height: 15px;
                background-color: var(--color-info);
                border-radius: 20px;
                margin-right: 10px;
                align-self: flex-start;
                //align-self: center;
            }
        }
        .inputs {
            display: grid;
            grid-template-columns: repeat(auto-fill, 100%);
            gap: 20px;
            width: 100%;
        }
    }
}
.button {
    width: 300px;
    align-self: center;
    margin-top: 30px;
    margin-bottom: 20px;
}
.warning {
    //padding: 20px 0 10px 0;
    color: orange;
    text-align: center;
    height: 50px;
}

.contentRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.item {
    margin-top: 40px;
    width: 100%;
    .title {
        font-size: 24px;
        font-weight: bolder;
        border-left: 5px solid var(--color-info);
        padding: 0 0 0 10px;
    }
    .itemData {
        display: flex;
        flex-direction: column;
        padding: 0 0 0 16px;
        .itemDataRow {
            font-size: 20;
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            &__label {
            }
            &__text {
                margin-left: 10px;
                color: #777;
            }
        }
    }
}
@include breakpoint(lessTablet) {
    .contentRow {
        flex-direction: column;
    }
    .item {
        .itemData {
            .itemDataRow {
                flex-direction: column;
                &__text {
                    margin-left: 0;
                    margin-top: 5px;
                }
            }
        }
    }
}

@include breakpoint(lessTablet) {
    .formContainer {
        .inputRow {
            flex-direction: column;
        }
    }
}
