import { RootState } from '@/store/rootReducer';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function PrivateRouteController() {
    const isAuthenticated = useSelector((state: RootState) => state.auth.login.isAuthenticated);

    const location = useLocation();

    return { isAuthenticated, location };
}

export default PrivateRouteController;
