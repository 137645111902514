import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './layout/Layout';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import PersonalInformation from './pages/PersonalInformation/PersonalInformation';
import Summary from './pages/Summary/Summary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './layout/Routes/PrivateRoute';
import PropertyInformation from './pages/PropertyInformation/PropertyInformation';
import ResumenInformation from './pages/ResumenInformation/ResumenInformation';

const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/personal-information" element={<PersonalInformation />} />
                    <Route path="/property-information" element={<PropertyInformation />} />
                    <Route path="/resumen-information" element={<ResumenInformation />} />
                    <Route path=""></Route>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/summary/:propertyCode"
                        element={
                            <PrivateRoute>
                                <Summary />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </Layout>
            <ToastContainer
                position="top-right"
                limit={3}
                autoClose={4000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </BrowserRouter>
    );
};

export default App;
