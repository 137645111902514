.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.containerItems {
    width: 100%;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3;
}
.input {
    width: calc(100% - 16px);
    border: 1px solid #777;
    border-radius: 5px 0 0 5px;
    padding: 7px;
    border-right: none;
}
.input:focus {
    outline: none;
}
.inputContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.buttonAdd {
    background-color: var(--color-info);
    color: white;
    width: 60%;
    height: 100%;
    min-height: 35px;
    min-width: 35px;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}
.buttonAdd:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}
.item {
    //width: calc(100% - 16px);
    width: 100%;
    background-color: white;
    border-radius: 5px;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__remove {
        width: 35px;
        background-color: red;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
    }
    &__label {
        padding: 7px;
    }
}
