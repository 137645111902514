import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import es from '../../assets/langs/es.json';
import fr from '../../assets/langs/fr.json';
import en from '../../assets/langs/en.json';
import { DEFAULT_LANG } from './translator';

i18n.use(initReactI18next).init({
    resources: {
        es: es,
        fr: fr,
        en: en
    },
    fallbackLng: DEFAULT_LANG,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
