import { combineReducers } from 'redux';
import { toggleLoaderSlice } from './loader.slice';

export const loaderReducer = combineReducers({
    setLoader: toggleLoaderSlice.reducer
});

export const loaderActions = {
    ...toggleLoaderSlice.actions
};
