@import '@/styles/tools/responsive';

.container {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 15px;
    border-radius: 15px;
    border: 1px solid var(--color-info);
}
.containerIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
}
.icon {
    width: 28px;
    height: 28px;
    color: var(--color-info);
}

.input {
    margin: 10px;
    border: none;
    color: var(--color-info);
    font-size: 24px;
    width: 100%;
}

.input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-info);
    opacity: 1;
    /* Firefox */
}

.input:focus {
    outline: none;
}
@include breakpoint(mobile) {
    .container {
        width: 90%;
    }
    .icon {
        width: 40px;
        align-self: center;
    }
    .input {
        width: 100%;
    }
}
