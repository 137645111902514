import { useState } from 'react';
import VisibilityIcon from '@/assets/icons/eye-fill.svg';
import VisibilityIconOff from '@/assets/icons/eye-slash-fill.svg';

const usePasswordToggle = () => {
    const [visible, setVisible] = useState(false);
    const InputType = visible ? 'text' : 'password';
    const Icon = (
        <>
            {visible === false ? (
                <div onClick={() => setVisible(true)}>
                    <VisibilityIcon />
                </div>
            ) : (
                <div onClick={() => setVisible(false)}>
                    <VisibilityIconOff />
                </div>
            )}
        </>
    );

    return [InputType, Icon];
};
export default usePasswordToggle;
