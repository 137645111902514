.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(240, 248, 255, 0.575);
    z-index: 50;
    position: absolute;
}
