import { combineReducers } from 'redux';
import { postPersonalInformationSlice } from './postPersonalInformation.slice';

export const personalInformationReducer = combineReducers({
    personalInformation: postPersonalInformationSlice.reducer
});

export const personalInformationActions = {
    ...postPersonalInformationSlice.actions
};
