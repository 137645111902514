.button {
    color: var(--color-white);
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .icon {
        width: 30px;
        align-self: center;
    }
    &__primary {
        border: 2px solid var(--color-gradient-1);
        background-image: var(--color-gradient-1);
        color: var(--color-white);

        &:hover,
        &:focus {
            opacity: 0.9;
        }
    }

    // &__primary {
    //     border: 2px solid var(--color-primary);
    //     background-color: var(--color-primary);
    //     color: var(--color-white);

    //     &:hover,
    //     &:focus {
    //         opacity: 0.9;
    //     }
    // }
    &__primaryOutLine {
        border: 2px solid var(--color-primary);
        background-color: var(--color-white);
        color: var(--color-primary);

        &:hover,
        &:focus {
            opacity: 0.9;
            border: 2px solid var(--color-primary);
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }
    &__secondary {
        border: 2px solid var(--color-black);
        background-color: var(--color-black);
        color: var(--color-white);

        &:hover,
        &:focus {
            opacity: 0.9;
        }
    }
    &__secondaryOutLine {
        border: 2px solid var(--color-secondary);
        background-color: var(--color-white);
        color: var(--color-secondary);

        &:hover,
        &:focus {
            opacity: 0.9;
            border: 2px solid var(--color-secondary);
            background-color: var(--color-secondary);
            color: var(--color-white);
        }
    }
    &__terciary {
        border: 2px solid var(--color-terciary);
        background-color: var(--color-terciary);
        color: var(--color-white);

        &:hover,
        &:focus {
            opacity: 0.9;
        }
    }
    &__terciaryOutLine {
        border: 2px solid var(--color-terciary);
        background-color: var(--color-white);
        color: var(--color-terciary);

        &:hover,
        &:focus {
            opacity: 0.9;
            border: 2px solid var(--color-terciary);
            background-color: var(--color-terciary);
            color: var(--color-white);
        }
    }

    &__success {
        border: 2px solid var(--color-success);
        background-color: var(--color-success);
        color: var(--color-white);
        &:hover,
        &:focus {
            opacity: 0.9;
        }
    }
    &__successOutLine {
        border: 2px solid var(--color-success);
        background-color: var(--color-white);
        color: var(--color-success);

        &:hover,
        &:focus {
            opacity: 0.9;
            border: 2px solid var(--color-success);
            background-color: var(--color-success);
            color: var(--color-white);
        }
    }
    &__info {
        border: 2px solid var(--color-info);
        background-color: var(--color-info);
        color: var(--color-white);
        &:hover,
        &:focus {
            opacity: 0.9;
        }
    }
    &__infoOutLine {
        border: 2px solid var(--color-info);
        background-color: var(--color-white);
        color: var(--color-info);

        &:hover,
        &:focus {
            opacity: 0.9;
            border: 2px solid var(--color-info);
            background-color: var(--color-info);
            color: var(--color-white);
        }
    }
    &__warning {
        border: 2px solid var(--color-warning);
        background-color: var(--color-warning);
        color: var(--color-white);
        &:hover,
        &:focus {
            opacity: 0.9;
        }
    }
    &__warningOutLine {
        border: 2px solid var(--color-warning);
        background-color: var(--color-white);
        color: var(--color-warning);
        &:hover,
        &:focus {
            opacity: 0.9;
            border: 2px solid var(--color-warning);
            background-color: var(--color-warning);
            color: var(--color-white);
        }
    }
    &__error {
        border: 2px solid var(--color-error);
        background-color: var(--color-error);
        color: var(--color-white);

        &:hover,
        &:focus {
            opacity: 0.7;
        }
    }
    &__errorOutLine {
        border: 2px solid var(--color-error);
        background-color: var(--color-white);
        color: var(--color-error);
        &:hover,
        &:focus {
            opacity: 0.9;
            border: 2px solid var(--color-error);
            background-color: var(--color-white);
            color: var(--color-error);
        }
    }

    &__disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }
    &__xsmall {
        font-size: 0.7rem;
        padding: 0.2rem;
    }
    &__small {
        font-size: 1rem;
        padding: 0.25rem;
    }
    &__medium {
        font-size: 1.2rem;
        padding: 0.3rem;
    }
    &__large {
        font-size: 1.5rem;
        padding: 0.35rem;
    }
    &__xlarge {
        font-size: 1.7rem;
        padding: 0.33rem;
    }
    &__adaptable {
        font-size: 2rem;
        padding: 0.4rem;
    }
}
