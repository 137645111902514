import classNames from 'classnames';
import { ButtonProps } from './Button.types';
import buttonStyles from './Button.module.scss';

const Button = ({
    children,
    onClick,
    disabled,
    type = 'button',
    form,
    variant,
    size,
    IconLeft,
    IconRight
}: ButtonProps) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={classNames(
                buttonStyles.button,
                buttonStyles[`button__${variant}`],
                buttonStyles[`button__${size}`],
                disabled ? buttonStyles['button__disabled'] : ''
            )}
            form={form}
            // eslint-disable-next-line react/button-has-type
            type={type}
        >
            {IconLeft && <IconLeft className={buttonStyles.icon}></IconLeft>}
            {children} {IconRight && <IconRight className={buttonStyles.icon}></IconRight>}
        </button>
    );
};

export default Button;
