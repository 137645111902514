import Button from '@/components/Button/Button';
import { ButtonSizes, ButtonVariants } from '@/components/Button/Button.types';
import { useNavigate } from 'react-router-dom';
import styles from './Home.module.scss';

const Home = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    SISTEMA DE LOCALIZACIÓN RURAL DEL MUNICIPIO DE JUMILLA
                </div>
            </div>

            <div className={styles.content}>
                <div className={styles.buttons}>
                    <div className={styles.button}>
                        <Button
                            size={ButtonSizes.LARGE}
                            variant={ButtonVariants.INFO}
                            onClick={() => navigate('/personal-information')}
                        >
                            REGISTRAR
                        </Button>
                    </div>
                    <div className={styles.button}>
                        <Button
                            size={ButtonSizes.LARGE}
                            variant={ButtonVariants.INFO}
                            onClick={() => navigate('/login')}
                        >
                            MIS DATOS
                        </Button>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default Home;
