import request, { ApiResponse } from '@/utils/helpers/apiResponse.helper';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export interface CitizenData {
    nif: string;
    name: string;
    last_name: string;
    email: string;
    phone_numbers: string[];
}
export interface PropertyData {
    address: string;
    latitude: number | null;
    longitude: number | null;
    is_company: boolean;
    has_elders: boolean;
    has_minors: boolean;
    people_on_property: number;
}
export interface PersonalInformationData {
    citizen: CitizenData;
    property: PropertyData;
}
export interface PostPersonalInformationData {
    data: PersonalInformationData;
}

export const postPersonalInformationThunk = createAsyncThunk<
    PersonalInformationData,
    PersonalInformationData,
    { rejectValue: Error }
>('postPersonalInformation', async (params, thunkApi) => {
    return request({
        url: '/api/citizens/register',
        method: 'POST',
        data: params
    })
        .then((res: ApiResponse<PersonalInformationData>) => {
            toast.success('Enviado correctamente');
            return res.data;
        })
        .catch((err: ApiResponse<Error>) => {
            toast.error('Falló al enviar el formulario');
            return thunkApi.rejectWithValue(err.data);
        });
});

export const postPersonalInformationSlice = createSlice({
    name: 'personalInformation',
    initialState: {
        data: {
            citizen: {
                nif: '',
                name: '',
                last_name: '',
                email: '',
                phone_numbers: []
            },
            property: {
                address: '',
                latitude: 0,
                longitude: 0,
                is_company: false,
                has_elders: false,
                has_minors: false,
                people_on_property: 0
            }
        }
    } as PostPersonalInformationData,
    reducers: {
        setCitizenInformation: (state, action) => {
            state.data.citizen = { ...action.payload };
        },
        setPropertyInformation: (state, action) => {
            state.data.property = { ...action.payload };
        }
    },
    extraReducers: {}
});

export const { setCitizenInformation } = postPersonalInformationSlice.actions;
