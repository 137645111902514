import usePasswordToggle from '@/utils/hooks/usePasswordToggle';
import styles from './InputPassword.module.scss';
import LockIcon from '@/assets/icons/lock-icon.svg';
interface InputProps {
    placeholder: string;
    onChange: (text: string) => void;
    value: string;
    [x: string]: any;
}

const InputPassword = ({ placeholder, onChange, ...props }: InputProps) => {
    const [inputType, Icon] = usePasswordToggle();

    return (
        <div className={styles.inputContent}>
            <div className={styles.containerIcon}>
                <LockIcon className={styles.icon} />
            </div>
            <input
                className={styles.input}
                placeholder={placeholder}
                onChange={(text) => onChange(text.currentTarget.value)}
                type={inputType as string}
                {...props}
            />
            <div className={styles.containerIcon}>
                <div className={styles.icon}>{Icon}</div>
            </div>
        </div>
    );
};

export default InputPassword;
