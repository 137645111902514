import * as Yup from 'yup';

export const PropertyInformationSchema = () => {
    const addressSchema = {
        lat: Yup.number().required(),
        lng: Yup.number().required(),
        address: Yup.string().required().trim()
    };
    const typeInputSchema = {
        address: Yup.object().shape(addressSchema),
        isCompany: Yup.boolean(),
        over65: Yup.boolean(),
        minor18: Yup.boolean(),
        numberPeople: Yup.number()
    };
    let schema = Yup.object(typeInputSchema);
    return schema;
};
