import CustomMap from '@/components/CustomMap/CustomMap';
import { useField } from 'formik';

export interface InputMapProps {
    name: string;
}
const InputMap = ({ name }: InputMapProps) => {
    const [field, meta, helpers] = useField(name);

    const { value } = meta;
    const { setValue } = helpers;
    return <CustomMap selected={value} setSelected={setValue} />;
};

export default InputMap;
