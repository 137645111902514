import { useField } from 'formik';
import styles from './DynamicToggle.module.scss';
interface DynamicToggleProps {
    label: string;
    data: DataToggle[];
    [x: string]: any;
}
export interface DataToggle {
    text: string;
    value: number | string;
}
export interface InputPosition {
    indexTable: number;
    indexData: number;
    indexInput: number;
}

const DynamicToggle = ({ data, name, ...props }: DynamicToggleProps) => {
    const [field, meta, helpers] = useField(name);

    const { value } = meta;
    const { setValue } = helpers;

    return (
        <>
            <div className={styles.container}>
                <div className={styles.containerItems} {...field} {...props}>
                    {data.map((e, i) => {
                        return (
                            <button
                                type="button"
                                className={value === e.value ? styles.item__active : styles.item}
                                onClick={() => setValue(e.value)}
                                value={e.value}
                                key={i + 'btn_tgg'}
                            >
                                {e.text}
                            </button>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default DynamicToggle;
